import React from "react";
import StockHome from "../Stocks/StockHome";

const Home = () => {
  return (
    <div >
      <StockHome />
    </div>
  );
};

export default Home;
